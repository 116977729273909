<template>
  <page-main
    title="What professional or volunteer organizations are you involved in?"
    :aside="[
      'Being involved in organizations - professional or volunteer - shows involvement in your community.'
    ]"
    nextText="Quick Review"
    buttonTabIndex="3"
    eventName="nav"
    @nav="nav('/ReviewGeneralInfo')"
    @save="save()"
  >
    <v-container>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-multi-select
            label="Professional Organizations"
            titleText="Professional Organizations you are a member of:"
            :optionsList="[]"
            :selected.sync="professionalOrg"
            tabindex="1"
            autofocus
          ></r-multi-select>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-multi-select
            label="Volunteer Organizations"
            titleText="Volunteer Organizations you are a part of:"
            :optionsList="[]"
            :selected.sync="volunteerOrg"
            tabindex="2"
          ></r-multi-select>
        </v-col>
      </v-row>
    </v-container>
  </page-main>
</template>

<script>
import * as Display from "../utility/display";
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";

// @ is an alias to /src
export default {
  name: "Organizations",
  data: () => ({}),
  components: {},
  methods: {
    save() {
      this.buildDisplay();
      this.saveOrganizations();
    },
    ...mapActions("organization", ["saveOrganizations"]),
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    buildDisplay() {
      let professionalOrgs = Display.BuildCommaString("", this.professionalOrg);

      let volunteerOrgs = Display.BuildCommaString("", this.volunteerOrg);

      this.professionalOrgDisplay = professionalOrgs;
      this.volunteerOrgDisplay = volunteerOrgs;

      return [professionalOrgs, volunteerOrgs];
    }
  },
  mounted() {},
  computed: {
    displayValues: function() {
      return this.buildDisplay();
    },
    ...mapFields("organization", [
      "professionalOrg",
      "professionalOrgDisplay",
      "volunteerOrg",
      "volunteerOrgDisplay"
    ])
  }
};
</script>
